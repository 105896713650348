import { useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { MdOutlineContactMail } from "react-icons/md";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import Country from "../../../config/countryCode.json";

import ContactModal from "../components/contactModal";
import FilePicker from "../components/filePicker";
import FileItem from "../components/fileItem";
import TemplateModal from "../components/templateModal";

import { FILE_UPLOAD, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import moment from "moment";

function Message(props: any) {
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(false);
  const [files, setFiles] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [form] = Form.useForm();

  const [filesModal, setFilesModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  const onFinish = async (values: any) => {
    console.log(values);
    try {
      setLoading(true);
      var file: any = files?.url;
      if (files?.file) {
        file = await FILE_UPLOAD(files?.file);
      }
      let obj = {
        file_url: file,
        number: (values?.countryCode + values?.number).substring(1),
        session: props?.data?.projectName,
        message: values?.message,
      };
      var response: any = await POST(API.MSG_SEND, obj);
      if (response?.status) {
        message.success("message send successfully");
      } else {
        message.error("oops.something went wrong");
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      message.error("oops.something went wrong");
    }
  };

  const getTemplate = (templat: any) => {
    message.success("Template Selected Successfully.");
    form.setFieldsValue({
      message: templat?.[0]?.content,
    });
    setFiles({
      file:null,
      url: templat?.[0]?.image,
    })
  };

  const Selector = (
    <Form.Item
      name="countryCode"
      noStyle
      rules={[{ required: true, message: "" }]}
    >
      <Select
        style={{ width: 85 }}
        placeholder={"Code"}
        size="large"
        showSearch={true}
      >
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const getContacts = (contact: any) => {
    message.success(`${contact?.[0]?.name}'s Phone Number has been Selected`);
    form.setFieldsValue({
      number: contact?.[0]?.phone,
      countryCode: contact?.[0]?.code,
    });
    setContact(false);
  };

  return (
    <div>
      <div className="Projects-txt1">Send Message</div>
      <Row>
        <Col sm={7} xs={12}>
          <div className="Projects-form">
            <Form onFinish={onFinish} form={form}>
              <Row>
                <Col sm={2} xs={4}>
                  <Button
                    size="large"
                    block
                    icon={<MdOutlineContactMail size={25} />}
                    onClick={() => setContact(true)}
                  ></Button>
                </Col>
                <Col sm={10} xs={8}>
                  <Form.Item
                    name={"number"}
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input
                      addonBefore={Selector}
                      size="large"
                      type="number"
                      placeholder="Enter Phone Number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="Projects-rowBox">
                <div className="Projects-Broadcasttxt1">Message</div>
                <div style={{ flex: 1 }} />
                <div style={{ marginBottom: 15 }}>
                  <Button
                    size="small"
                    onClick={() => setTemplateModal(true)}
                  >
                    Templates
                  </Button>
                </div>
              </div>
              <Form.Item
                name={"message"}
                rules={[{ required: true, message: "required" }]}
              >
                <Input.TextArea
                  size="large"
                  rows={5}
                  placeholder="Enter Message"
                />
              </Form.Item>
              {files?.url ? (
                <Form.Item>
                  <FileItem files={files} remove={() => setFiles(null)} />
                </Form.Item>
              ) : null}
              <Row>
                <Col sm={4} xs={4}>
                  <Button
                    block
                    size="large"
                    icon={<IoDocumentAttachOutline />}
                    style={{ height: 50 }}
                    onClick={() => setFilesModal(true)}
                  >
                    Attachments
                  </Button>
                </Col>
                <Col sm={8} xs={8}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    style={{ height: 50 }}
                    htmlType="submit"
                    loading={loading}
                    icon={<IoMdSend />}
                  >
                    Send Message
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col sm={5} xs={12}>
          <div className="Projects-box3">
            <div>
              <div className="Projects-txt2">Project ID</div>
              <div className="Projects-txt3">#0000{props?.data?.id}</div>
            </div>
            <br />
            <div>
              <div className="Projects-txt2">Whatsapp Number</div>
              <div className="Projects-txt3">
                {props?.data?.countryCode} {props?.data?.hostPhoneNumber}
              </div>
            </div>
            <br />
            <div>
              <div className="Projects-txt2">Created On</div>
              <div className="Projects-txt3">
                {moment(props?.data?.createdAt).format("llll")}
              </div>
            </div>
          </div>
          <br />
          <div className="Projects-box3">
            <div className="Projects-txt3">Reverify Whatsapp</div>
          </div>
          <br />
          <div className="Projects-box3">
            <div className="Projects-txt3">Change Number</div>
          </div>
          <br />
        </Col>
      </Row>
      {contact ? (
        <ContactModal
          open={contact}
          onCancel={() => setContact(false)}
          getContacts={getContacts}
          multi={false}
        />
      ) : null}
      {filesModal ? (
        <FilePicker
          open={filesModal}
          fileURL={files?.url}
          onChange={(val: any) => setFiles(val)}
          onCancel={() => setFilesModal(false)}
        />
      ) : null}
        {templateModal ? (
        <TemplateModal
          open={templateModal}
          onCancel={() => setTemplateModal(false)}
          getTemplate={getTemplate}
        />
      ) : null}
    </div>
  );
}

export default Message;
