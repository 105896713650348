import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LiaUserCircleSolid } from "react-icons/lia";
import moment from "moment";
import { Button, Popconfirm } from "antd";
import { logout } from "../../redux/slices/userSlice";
function AdminHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logouts = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="AdminHeader">
      <div className="AdminHeadertxt">{moment().format("DD, MMMM YYYY")}</div>
      <div className="AdminHeadertxt2">Help</div>
      <div className="AdminHeadertxt2">Configuration</div>
      <div className="AdminHeadertxt2">Develop</div>
      <div className="AdminHeaderBox3">
        <LiaUserCircleSolid size={40} />
      </div>
      <div className="AdminHeaderBox2">
        <Popconfirm
          title="Logout"
          description="Are you sure to logout ?"
          onConfirm={() => logouts()}
          okText="Yes"
          cancelText="No"
        >
          <Button block size="small" danger>
            Logout
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

export default AdminHeader;
