import PageHeader from "../route/pageHeader";
import { LuLayoutDashboard } from "react-icons/lu";
import Charts from "./charts";
import TopUsing from "./topUsing";

function Dashboard() {
  return (
    <div className="Dashboard">
       <PageHeader title={"Overview"} Icon={<LuLayoutDashboard/>}/>
        <Charts data={[]}/>
        <br/>
        <TopUsing/>
    </div>
  );
}

export default Dashboard;
