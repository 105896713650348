import { useNavigate } from "react-router-dom";
function MenuCard(props:any) {
  const navigation = useNavigate();
  return <div className="User-MenuCard">
    <div style={{fontSize:25,color:"#3468eb"}}>{props?.icon}</div>
    <div style={{margin:5}}/>
    <div>{props?.title}</div>
    </div>;
}

export default MenuCard;
