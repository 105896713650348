import { Pagination, Popconfirm, Space, Table } from "antd";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineContactMail } from "react-icons/md";

function DataTables({
  data,
  selected,
  deleteContact,
  details,
  selectItem,
  page,
  pageSize,
  meta,
  changePage,
}: any) {
  const columns = [
    {
      title: "",
      width: 10,
      render: (_: any, record: any) => (
        <div onClick={() => details(selected?.id === record?.id ? {} : record)}>
          <MdOutlineContactMail size={20} color="grey" />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (numb: string, item: any) => (
        <span>
          +{item?.code} {numb}
        </span>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Action",
      key: "action",
      width: 20,
      render: (_: any, record: any) => (
        <Space size="middle">
          <a>
            <CiEdit size={20} onClick={() => selectItem(record)} />
          </a>
          <a>
            <Popconfirm
              placement="bottomRight"
              title={"Are you sure to delete?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteContact(record)}
            >
              <MdDeleteOutline size={20} color="red" />
            </Popconfirm>
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.total_count || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </>
  );
}

export default DataTables;
