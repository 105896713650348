import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import { logout } from "../../redux/slices/userSlice";
import { BsBuildings } from "react-icons/bs";
import { BsCashCoin } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { IoListSharp } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
function SideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logouts = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="Sidebar">
      <div className="AdminHeaderBox1">
        <img src={logo} alt="logo" onClick={() => navigate("/admin/home")} />
      </div>
      <div className="Sidebar-Box">
      <div className="Sidebar-item"   onClick={() => navigate("/admin/home")}>
        <div>Overview</div>
        <div><LuLayoutDashboard size={20}/></div>
      </div>
      <div className="Sidebar-item"   onClick={() => navigate("/admin/companies")}>
        <div>Companies</div>
        <div><BsBuildings size={20}/></div>
      </div>
      <div className="Sidebar-item"   onClick={() => navigate("/admin/payments")}>
        <div>Payments</div>
        <div><BsCashCoin size={20}/></div>
      </div>
      <div className="Sidebar-item"   onClick={() => navigate("/admin/settings")}>
        <div>Settings</div>
        <div><IoSettingsOutline size={20}/></div>
      </div>
      </div>
      <div className="Sidebar-Box"   onClick={() => navigate("/admin/logs")}>
      <div className="Sidebar-item">
        <div>Logs</div>
        <div><IoListSharp size={20}/></div>
      </div>
      </div>
      <div style={{flex:1}}/>
      <div className="Sidebar-txt1">Powerd By Bairuha Tech</div>
    </div>
  );
}

export default SideBar;
