import "./styles.scss";
import Header from "../../components/header";

function ContactScreen() {

  return (
    <div className="ContactScreen">
      <Header />
    </div>
  );
}

export default ContactScreen;
