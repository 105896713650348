import React, { useState } from "react";
import { Button, Modal } from "antd";
import { read, utils } from 'xlsx';

const ExcelModal = (props: any) => {



  return (
    <Modal
      title={<div className="Projects-txt1">Import Contacts</div>}
      open={props?.open}
      onOk={()=>props?.onCancel()}
      onCancel={()=>props?.onCancel()}
      footer={false}
    >
      <div style={{height:"40vh"}}></div>
    </Modal>
  );
};

export default ExcelModal;
