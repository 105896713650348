

function CoastUsage() {
  return (
    <div>
        CoastUsage
    </div>
  );
}

export default CoastUsage;
