import React, { useState } from "react";
import { Modal, message } from "antd";
import { Button, Form, Input } from "antd";
import { FILE_UPLOAD, POST, PUT } from "../../utils/apiCalls";
import ImagePicker from "../../components/imagePicker";
import API from "../../config/api";
const FormModal = (props: any) => {
  const [form] = Form.useForm();
  const [loading2, setLoading2] = useState(false);
  const [files, setFiles] = useState<any>({url:props?.data?.image});

  const modalClose = () => {
    form.resetFields();
    props?.close();
  };

  const formSubmitHandler = async (values: any) => {
    try {
      setLoading2(true);
      var file: any = files?.url;
      if (files?.file) {
        file = await FILE_UPLOAD(files?.file);
      }
      const obj = {
        ...values,
        image: file,
      };
      const response: any = props?.data?.id
        ? await PUT(API.TEMPLATES_EDIT + props?.data?.id, obj)
        : await POST(API.TEMPLATES_ADD, obj);
      if (response?.status) {
        message.success(
          `Template ${props?.data?.id ? "Added" : "Updated"} Successfully.`
        );
        props?.onChange();
        modalClose();
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong...");
    } finally {
      setLoading2(false);
    }
  };

  return (
    <Modal
      title={
        <div style={{ fontSize: 25 }}>{`${
          props?.data?.id ? "Update" : "Add "
        } Template`}</div>
      }
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => modalClose()}
    >
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
        initialValues={{
          name: props?.data?.name,
          content: props?.data?.content,
          image: props?.data?.image,
        }}
      >
        <Form.Item
          label="Name"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please Enter Name",
            },
          ]}
        >
          <Input placeholder="Name" size="large" />
        </Form.Item>
        <Form.Item
          label="Content"
          name={"content"}
          rules={[
            {
              required: true,
              message: "Please Enter Content",
            },
          ]}
        >
          <Input.TextArea rows={5} placeholder="Content" size="large" />
        </Form.Item>
        <Form.Item
          label="Attachments"
          name={"image"}
          rules={[
            {
              required: true,
              message: "Please Input Image",
            },
          ]}
        >
          <ImagePicker
            desc={""}
            type={["png"]}
            fileURL={files?.url}
            onChange={(value: any) => setFiles(value)}
          />
        </Form.Item>

        <div className="d-flex gap-2 justify-content-end">
          <Button onClick={() => modalClose()} size="large">
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading2}
            onClick={() => form.submit()}
            size="large"
          >
            {props?.data?.id ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;
