import { Table } from "antd";

function History(props: any) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  return (
    <div style={{ paddingBottom: 20 }}>
      <div className="Projects-txt1">Message History</div>
      <div className="Projects-History">
        <Table size="small" dataSource={dataSource} columns={columns} />
      </div>
    </div>
  );
}

export default History;
