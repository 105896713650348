import Dropzone from "react-dropzone";
import { Modal, message } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { useState } from "react";
import moment from "moment";

function FilePicker(props: any) {
  const [isloading, setIsloading] = useState(false);

  const checkFileType = async (file: any) => {
    var parts = file.name.split(".");
    if (parts.length > 1 && parts[parts.length - 1] !== "") {
      return parts[parts.length - 1];
    } else {
      return null;
    }
  };

  const uploadImage = async (file: any) => {
    try {
      var myFile = file[0];
      let type = await checkFileType(myFile);
      let name = moment(new Date()).unix();
      const myNewFile = new File([myFile], name + `N.${type}`, {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let obj = {
        file: myNewFile,
        url: url,
      };
      props?.onChange(obj);
      props?.onCancel();
    } catch (error) {
      console.log(error);
      setIsloading(false);
      message.error("File Format Not supported");
    }
  };

  return (
    <Modal
      title={<div className="Projects-txt1">Attach Files</div>}
      open={props?.open}
      onOk={() => props?.onCancel()}
      onCancel={() => props?.onCancel()}
      footer={false}
    >
      <Dropzone onDrop={uploadImage}>
        {({ getRootProps, getInputProps }) => (
          <section className="Projects-FilePicker">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {props.fileURL ? (
                <img src={props.fileURL} className="Projects-FilePickerImg" />
              ) : (
                <div className="Projects-FilePickerBox">
                  <FiUploadCloud size={50} color="grey" />
                  <br /> <br />
                  <div>
                    Click or drag <span style={{ color: "blue" }}>here</span> to
                    select File
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </Modal>
  );
}

export default FilePicker;
