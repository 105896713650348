import "./styles.scss";
import { message } from "antd";
import moment from "moment";
import Dropzone from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

const ImagePicker = (props: any) => {
  const checkFileType = async (file: any) => {
    var parts = file.name.split(".");
    if (parts.length > 1 && parts[parts.length - 1] !== "") {
      return parts[parts.length - 1];
    } else {
      return null;
    }
  };

  const uploadImage = async (file: any) => {
    try {
      var myFile = file[0];
      let type = await checkFileType(myFile);
      let name = moment(new Date()).unix();
      const myNewFile = new File([myFile], name + `v.${type}`, {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let obj = {
        file: myNewFile,
        url: url,
      };
      props?.onChange(obj);
    } catch (error) {
      console.log(error);
      message.error("File Format Not supported");
    }
  };

  const clear = () => {
    let obj = {
      file: null,
      url: null,
    };
    props?.onChange(obj);
  };

  return (
    <div className="ImagePicker" style={props?.style}>
      <Dropzone onDrop={uploadImage}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {props.fileURL ? (
                <img src={props.fileURL} className="ImagePicker-img" />
              ) : (
                <div className="ImagePicker-box">
                  <FiUploadCloud size={50} color="grey" />
                  <br /> 
                  <div>
                    Click or drag <span style={{ color: "blue" }}>here</span> to
                    select File
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {props.fileURL?<div className="ImagePicker-clear" onClick={() => clear()}>
        clear
      </div>:null}
    </div>
  );
};

export default ImagePicker;
