import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
function PageHeader(props: any) {
  const navigation = useNavigate();
  return (
    <div className="User-pageHeader">
      {props?.icon ? (
        <div className="User-pageHeaderBox">{props?.icon}</div>
      ) : null}
      <div>
        <div className="User-pageHeadertxt1">{props?.title}</div>
        <div className="User-pageHeadertxt2">{props?.desc}</div>
      </div>
      <div style={{ flex: 1 }} />
      {props?.children}
      <div style={{ marginRight: 20 }} />
      {props?.back ? (
        <div onClick={() => navigation(-1)}>
          <MdClose size={30} color="red" />
        </div>
      ) : null}
    </div>
  );
}

export default PageHeader;
