import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from "antd";

import { login } from "../../../redux/slices/userSlice";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";

function Login(props: any) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      const obj = {
        email: val?.email,
        password: val?.password,
      };
      const url = API.LOGIN;
      const response: any = await POST(url, obj);
      if (response.status) {
        dispatch(login(response?.data));
        message.success("login success");
        if (response?.data?.userType === "user") {
          navigation(`/auth/`);
        } else if (response?.data?.userType === "admin") {
          navigation(`/admin/`);
        }
      } else {
        message.error("login faild");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      message.error("login faild");
    }
  };

  return (
    <div>
      <div className="LoginScreen-txt2">
        offering a suite of features tailored to streamline communication
      </div>
      <div className="LoginScreen-txt3">Login Your Account</div>
      <Form onFinish={onFinish}>
        <Form.Item
          name={"email"}
          rules={[{ required: true, message: "required" }]}
        >
          <Input placeholder="Enter email" size="large" />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[{ required: true, message: "required" }]}
        >
          <Input.Password placeholder="Enter password" size="large" />
        </Form.Item>
        <div
          className="LoginScreen-txt4"
          onClick={() => props.chengeMode("Forgot")}
        >
          Forgot Password?
        </div>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            style={{ height: 50 }}
            loading={isLoading}
          >
            Login
          </Button>
        </Form.Item>
        <div
          className="LoginScreen-txt1"
          onClick={() => props.chengeMode("Signup")}
        >
          Dont have an account?
        </div>
      </Form>
    </div>
  );
}

export default Login;
