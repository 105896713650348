import "./styles.scss";
import AdminHeader from "./header";
import SideBar from "./sideBar";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import Dashboard from "../dashboard";
import Companies from "../companies";
import Payments from "../payments";
import Settings from "../settings";
import Logs from "../logs";
import Projects from "../projects";
import Project from "../project";


function AdminRoute() {
  const { Sider } = Layout;
  return (
    <Layout className="AdminRoute">
      <Sider
        width={260}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          zIndex: 1000,
          backgroundColor: "#fff",
        }}
        className="AdminRoute-Sider"
      >
        <SideBar />
      </Sider>
      <Layout style={{ marginLeft: 260 }}>
        <AdminHeader />
        <div className="AdminRoute-box">
          <br />
          <Container>
            <Routes>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/projects/:id" element={<Projects />} />
              <Route path="/project/:id" element={<Project/>}/>
            </Routes>
          </Container>
        </div>
      </Layout>
    </Layout>
  );
}

export default AdminRoute;
