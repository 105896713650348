import { Modal } from "antd";
import moment from "moment";

const PreviewModal = (props: any) => {
  return (
    <Modal
      title={<div className="Projects-txt1">Message Preview</div>}
      open={props?.open}
      onOk={()=>props?.onCancel()}
      onCancel={()=>props?.onCancel()}
      footer={false}
      width={500}
    >
      <div className="Projects-msgPrevew">
        <div className="Projects-msgPrevewItem">
          <div style={{width:"30%"}}/>
        <div className="Projects-msgPrevewBox">
          <div>Image</div>
          <div className="Projects-msgPrevewtxt1">{props?.data?.message}</div>
          <div className="Projects-msgPrevewtxt2">{moment().format("ll")}</div>
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
