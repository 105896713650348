import "./styles.scss";
import { useNavigate } from "react-router-dom";
function PageHeader(props:any) {
  const navigate = useNavigate();

  return (
    <div className="Admin-PageHeader">
        {props?.Icon?<div className="PageHeader-icon">{props?.Icon}</div>:null}
        <div className="PageHeader-txt1">{props?.title}</div>
        <div style={{flex:1}}/>
        <div>{props?.children}</div>
    </div>
  );
}

export default PageHeader;
