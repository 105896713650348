import { Input, Tag, Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import { LuClipboardList } from "react-icons/lu";
import { FiAlertTriangle } from "react-icons/fi";
import API from "../../../config/api";
import { useState } from "react";
function Apises(props: any) {
  const [copiedSingle, setCopiedSingle] = useState(false);
  const [copiedBroadCast, setCopiedBroadCast] = useState(false);
  const [token, setCopiedToken] = useState(false);

  let companyId = 1234567890;
  let projectId = 1234567890;

  const handleCopy = async (
    text: string,
    setCopied: (value: boolean) => void
  ) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 700);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };
  let authToken = props?.data?.token;
  console.log("aut", authToken);
  let reqObjBroadcast = {
    attachment: "add you attachment",
    message: "add your message",
    to: ["911234567890", "911234567890", "9711234567"],
    batchSize: 30,
  };
  let reqObjSingle = {
    attachment: "add you attachment",
    message: "add your message",
    to: "911234567890",
    session: 30,
  };
  // const curlCmd = `curl -X POST -H "Content-Type: application/json" -d '${JSON.stringify(
  //   reqObj
  // )}' ${API.BASE_URL + API.BROADCAST} `;
  const curlCmdBroadCast = `curl -X POST -H "Content-Type: application/json" -H "Authorization: Bearer ${authToken}" -d '${JSON.stringify(
    reqObjBroadcast
  )}' ${API.BASE_URL + API.BROADCAST_V1}`;
  const curlCmdSingle = `curl -X POST -H "Content-Type: application/json" -H "Authorization: Bearer ${authToken}" -d '${JSON.stringify(
    reqObjSingle
  )}' ${API.BASE_URL + API.SINGLE_MESSAGE_V1}`;

  return (
    <div style={{ paddingBottom: 20 }}>
      <div className="Projects-txt1">API Keys</div>
      <div className="Projects-Apises">
        <Row>
          <Col sm={6} xs={12}>
            <div>
              Your secret API keys are listed below. Please note that we do not
              display your secret API keys again after you generate them.
            </div>
            <br />
            <div>
              <FiAlertTriangle size={20} color="orange" /> Do not share your API
              key with others, or expose it in the browser or other client-side
              code. In order to protect the security of your account, OpenAI may
              also automatically rotate any API key that we've found has leaked
              publicly.
            </div>
            <br />
            <div>
              <div className="Projects-rowBox" style={{ marginBottom: 10 }}>
                <div>
                  <strong>Your API key</strong>
                </div>
                <div style={{ flex: 1 }} />
                <Tag color="green">Active</Tag>
              </div>
              <Input
                size="large"
                value={props?.data?.token}
                suffix={
                  <Tooltip
                    title={<div className="copiedTxt">copied</div>}
                    open={token}
                    arrow={false}
                  >
                    <LuClipboardList
                      className="icons"
                      onClick={() =>
                        handleCopy(props?.data?.token, setCopiedToken)
                      }
                    />
                  </Tooltip>
                }
              />
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div className="Projects-Apisestxt1">&#x2022; Single Message</div>
            <div className="Projects-ApisesBox">
              <div className="Projects-rowBox">
                <div>
                  <span style={{ color: "green" }}>POST</span> /v1/message
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <Tooltip
                    title={<div className="copiedTxt">copied</div>}
                    open={copiedSingle}
                    arrow={false}
                  >
                    <LuClipboardList
                      className="icons"
                      onClick={() => handleCopy(curlCmdSingle, setCopiedSingle)}
                    />
                  </Tooltip>
                </div>
              </div>
              <hr />
              <pre>
                <code>
                  {`{
  "to": "911234567890",  `}
                  <span
                    style={{ color: "grey" }}
                  >{`// with country code`}</span>
                  {`
  "message": "your message",
  "session": "your session",
  "attachment": "https://your-attachment.com/1234567890.docx",
}`}
                </code>
              </pre>
            </div>
            <br />
            <div className="Projects-Apisestxt1">
              &#x2022; Boradcast Message
            </div>
            <div className="Projects-ApisesBox">
              <div className="Projects-rowBox">
                <div>
                  <span style={{ color: "green" }}>POST</span> /v1/broadcast
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <Tooltip
                    title={<div className="copiedTxt">copied</div>}
                    open={copiedBroadCast}
                    arrow={false}
                  >
                    <LuClipboardList
                      className="icons"
                      onClick={() =>
                        handleCopy(curlCmdBroadCast, setCopiedBroadCast)
                      }
                    />
                  </Tooltip>
                </div>
              </div>
              <hr />
              <pre>
                <code>
                  {`{
  "to": [
    "911234567890",`}{" "}
                  <span
                    style={{ color: "grey" }}
                  >{`// with country code`}</span>
                  {`
    "911234567890",
  ],
  "message": "your message",
  "attachment": "https://your-attachment.com/1234567890.docx",
  "batchSize": 10
}`}
                </code>
              </pre>
            </div>
            <br />
            <div className="Projects-Apisestxt1">&#x2022; API response</div>

            <div className="Projects-ApisesBox">
              <div className="Projects-rowBox">
                <div>HTTP STATUS CODE SUMMARY</div>
              </div>
              <hr />
              <div>200 - OK: The request was successful.</div>
              <div>400 - Bad Request: The request was invalid.</div>
              <div>401 - Unauthorized: invalid authentication.</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Apises;
