import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Bg1 from "../../assets/images/bg.png";

import Login from "./components/loginScreen";
import { useState } from "react";
import SignupScreen from "./components/signupScreen";
import ForgotPassword from "./components/forgotPassword";

function LoginScreen() {
  const [modeLog, setSetModeLog] = useState("Login");
  return (
    <div className="LoginScreen">
      <Container fluid>
        <Row>
          <Col sm={1} xs={12} style={{ margin: 0, padding: 0 }}></Col>
          <Col sm={4} xs={12}>
            <div className="LoginScreen-Box">
              <img src={Logo} className="LoginScreen-img" />

              {modeLog === "Login" ? (
                <Login chengeMode={(val: any) => setSetModeLog(val)} />
              ) : null}
              {modeLog === "Signup" ? (
                <SignupScreen chengeMode={(val: any) => setSetModeLog(val)} />
              ) : null}
              {modeLog === "Forgot" ? (
                <ForgotPassword chengeMode={(val: any) => setSetModeLog(val)} />
              ) : null}
              <br/>
               <div className="LoginScreen-txt5">
              Contact Us  &#x2022; Terms and conditons &#x2022; Privacy Policy
            </div>
            </div>
          </Col>
          <Col sm={1} xs={12} style={{ margin: 0, padding: 0 }}></Col>
          <Col sm={6} xs={12} style={{ margin: 0, padding: 0 }}>
            <img src={Bg1} className="LoginScreen-bg1" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginScreen;
