import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import { Popconfirm, Popover } from "antd";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Tables(props: any) {
  const navigate = useNavigate();
  return (
    <Row>
      {props?.data?.map((item: any, index: number) => (
        <Col md={3}>
          <div className="project-table" onClick={()=>navigate(`/admin/project/${item.id}`)}>
            <div className="delete-div">
              <div>
                <h5>{item?.name}</h5>
                <p>{item?.bundleId}</p>
              </div>
              <div className="icon-div">
              <TiEdit
                size={25}
                color="turquoise"
                onClick={() => {
                  props.setSelectedItem(item);
                  props.setOpenForm(true);
                }}
              />
              <Popconfirm
                placement="left"
                title={"Are you sure to delete?"}
                description={"You will not be able to retrieve this data."}
                onConfirm={() => props.delete(item?.id)}
                okText="Yes"
                cancelText="No"
              >
                <MdDelete size={25} color="red" />
              </Popconfirm>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Tables;
