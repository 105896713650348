import "./styles.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, message } from "antd";
import PageHeader from "../route/pageHeader";
import { Row, Col } from "react-bootstrap";
import Country from "../../config/countryCode.json";
import Instruction from "./components/instruction";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { BiCommentAdd } from "react-icons/bi";

import API from "../../config/api";
import { POST } from "../../utils/apiCalls";

function NewProject() {
  const navigation = useNavigate();
  const User = useSelector((state: any) => state.User);
  const [scan, setScan] = useState(false);
  const [qrcode, setQrcode] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onFinish = (values: any) => {
    setLoading(true);
    setData(values);
    generateQr(values);
  };

  const generateQr = async (values: any) => {
    try {
      values["session"] = (values?.projectName).replace(/\s/g, "");
      let obj = {
        name_session: values?.session,
        hostPhoneNumber: values?.countryCode + values?.hostPhoneNumber,
      };
      var response: any = await POST(API.PROJECT_QR, obj);
      if (response?.status) {
        setQrcode(response?.data);
        setScan(true);
        setTimeout(() => {
          setLoading(false);
        }, 20000);
      }
    } catch (err) {
      setLoading(false);
      message.error("oops.something went wrong");
    }
  };

  const create = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        companyId: Number(User?.user?.id),
        projectName: values?.projectName,
        countryCode: values?.countryCode,
        hostPhoneNumber: values?.hostPhoneNumber,
        MessagesLimit: 0,
        usedMessages: 0,
        isVerified: true,
        amount: 0,
        session: values?.session,
      };
      var response: any = await POST(API.PROJECT_ADD, obj);
      if (response?.status) {
        message.success("Project created successfully");
        navigation(`/auth/project/${response?.data?.id}`);
      } else {
        message.error("Faild to create project");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error("oops.something went wrong");
    }
  };

  const Selector = (
    <Form.Item
      name="countryCode"
      noStyle
      rules={[{ required: true, message: "" }]}
    >
      <Select
        style={{ width: 85 }}
        placeholder={"Code"}
        size="large"
        showSearch={true}
      >
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div>
      <PageHeader
      icon={<BiCommentAdd/>}
        title={"Create New Project"}
        desc={"Create new Project here"}
        back={true}
      />
      <br />
      <Row>
        <Col sm={4} xs={12}>
          <Form onFinish={scan ? create : onFinish}>
            <label>Project Name</label>
            <Form.Item
              name={"projectName"}
              rules={[{ required: true, message: "required" }]}
            >
              <Input size="large" placeholder="Enter Project Name" />
            </Form.Item>
            <label>Whatsapp Number</label>
            <Form.Item
              name={"hostPhoneNumber"}
              rules={[{ required: true, message: "required" }]}
            >
              <Input
                addonBefore={Selector}
                style={{ width: "100%" }}
                type="number"
                size="large"
                placeholder="Enter Phone Number"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                style={{ height: 50 }}
                htmlType="submit"
                loading={loading}
              >
                {scan ? "Continue Phone Number" : "Verify Phone Number"}
              </Button>
            </Form.Item>
          </Form>
          <br />
          <div className="NewProject-txt1">
            After Clicking Verify Number Button a QR code is visible in right
            side then follow below steps
          </div>
          <hr />
          <Instruction />
        </Col>
        <Col sm={2} xs={12}></Col>
        <Col sm={4} xs={12}>
          {scan ? (
            <div className="NewProject-box">
              <QRCode value={qrcode} />
              <div className="NewProject-txt2">Scan Now Link QR</div>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default NewProject;
