import PageHeader from "../route/pageHeader";
import { BsCashCoin } from "react-icons/bs";
function Payments() {
  return (
    <div className="Payments">
        <PageHeader title={"Payments"} Icon={<BsCashCoin/>}/>
    </div>
  );
}

export default Payments;
