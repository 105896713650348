import PageHeader from "../route/pageHeader";
import { IoSettingsOutline } from "react-icons/io5";
function Settings() {
  return (
    <div className="Settings">
        
        <PageHeader title={"Settings"} Icon={<IoSettingsOutline/>}/>
    </div>
  );
}

export default Settings;
