import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, InputNumber, Select, message } from "antd";
import { Row, Col } from "react-bootstrap";
import Industries from "../../../config/Industries.json";
import Country from "../../../config/countryCode.json"

import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { login } from "../../../redux/slices/userSlice";

function SignupScreen(props: any) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      const obj = {
        companyName: val?.Companyname,
        countryCode:val?.code,
        email: val?.email,
        password: val?.password,
        phoneNumber: JSON.stringify(val?.phoneno),
        companyType: val?.industry,
        userType:"user",
        status:true
      };
      const url = API.SIGN_UP;
      const response: any = await POST(url, obj);
      if (response.status) {
        message.success("account creation successfully");
        dispatch(login(response?.data));
        if (response?.data?.userType === "user") {
          navigation(`/auth/`);
        } else if (response?.data?.userType === "admin") {
          navigation(`/admin/`);
        }
      } else {
        message.error("sign up faild");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      message.error("sign up faild");
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 85 }} placeholder={"Code"} size="large" showSearch={true}>
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div>
      <div className="LoginScreen-txt3">Create VerifyLink Account</div>
      <Form onFinish={onFinish}>
        <Form.Item name={"Companyname"}  
        rules={[{required: true, message: "required"}]}>
          <Input placeholder="Enter Companyname" size="large" />
        </Form.Item>
        <Form.Item name={"phoneno"} rules={[{required: true, message: "required"}]}>
          <InputNumber
            addonBefore={Selector}
            style={{ width: "100%" }}
            placeholder="Enter Mobile No."
            size="large"
            controls={false}
          />
        </Form.Item>
        <Form.Item name={"email"} rules={[{required: true, message: "required"}]}>
          <Input placeholder="Enter Email address" size="large" />
        </Form.Item>
        <Form.Item name={"industry"} rules={[{required: true, message: "required"}]}>
          <Select
            showSearch={true}
            size="large"
            placeholder={"Select industry"}
          >
            {Industries?.map((item: any) => {
              return (
                <Select.Option key={item?.id} value={item?.name}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"password"}
              rules={[
                { required: true, message: "required" },
              ]}
            >
              <Input.Password size="large" placeholder="Enter password" />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"confirm"}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "required",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const password = getFieldValue("password");
                    if (!value || password === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The passwords do not match!")
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password size="large" placeholder="Confirm password" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            style={{ height: 50 }}
            loading={isLoading}
          >
            Sign Up
          </Button>
        </Form.Item>
        <div
          className="LoginScreen-txt1"
          onClick={() => props.chengeMode("Login")}
        >
          I have an account?
        </div>
      </Form>
    </div>
  );
}

export default SignupScreen;
