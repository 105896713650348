import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, message, notification } from "antd";
import PageHeader from "../route/pageHeader";
import { BsBuildings } from "react-icons/bs";
import Tables from "./table";
import Forms from "./forms";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";
import { useParams } from "react-router-dom";

function Projects() {
  const [loading, setLoading] = useState(true);
  const [Notifications, contextHolder] = notification.useNotification();
  const [projectData, setProjectData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const { Search } = Input;
  const { id } = useParams();
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(16);

  useEffect(() => {
    getProjectData(page, take);
  }, []);

  const getProjectData = async (p: any, t: any) => {
    try {
      const companyId = id;
      setLoading(true);
      let url =
        API.GET_PROJECT +
        `?order=DESC&page=${p}&take=${t}&companyId=${companyId}`;
      const Project: any = await GET(url, {});
      if (Project?.status) {
        setProjectData(Project?.data);
        setMeta(Project?.meta);
      } else {
        setProjectData([]);
        showNotification("error", "No data found");
      }
    } catch (err) {
      setLoading(false);
      showNotification("error", "Network error!", "Unable to fetch data");
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  const showNotification = (
    type: string,
    message: string,
    description?: string
  ) => {
    const { success, error } = notification;
    if (type === "success") {
      success({ message });
    } else {
      error({ message, description });
    }
  };

  const deleteItem = async (id: any) => {
    const url = API.GET_PROJECT + "/" + id;
    try {
      const response: any = await DELETE(url);
      if (response) {
        message.success("Succesfully Deleted");
        getProjectData(page, take);
      } else {
        message.error("Something went wrong");
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const onSearch = async (value: any) => {
    setLoading(true);
    try {
      const url = `${API.FILTER_PROJECT}?name=${
        value ?? ""}&companyId=${id}`;
      const response: any = await GET(url, {});
      if (response.data.length === 0) {
        message.warning("No projects found for the provided criteria.");
      } else {
        setProjectData(response.data);
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
    } finally {
      setLoading(false);
    }
  };

  const pagination = (page: any, take: any) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    getProjectData(page, take);
  };

  return (
    <div className="Project">
      <PageHeader title={"Project"} Icon={<BsBuildings />}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Search
            type="primary"
            size="large"
            enterButton="Search"
            placeholder="Search Projects"
            onSearch={onSearch}
          />
          &nbsp; &nbsp;
          <Button size="large" onClick={() => setOpenForm(true)}>
            Add Project +
          </Button>
        </div>
      </PageHeader>
      <Tables
        setSelectedItem={setSelectedItem}
        setOpenForm={setOpenForm}
        data={projectData}
        delete={(id: any) => deleteItem(id)}
      />
      <div className="pagination">
        <Pagination
          size="small"
          defaultCurrent={meta.page}
          total={meta.itemCount}
          pageSize={meta?.take ? meta?.take : 0}
          onChange={(page, take) => pagination(page, take)}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
      {openForm ? (
        <Forms
          visible={openForm}
          id={id}
          data={selectedItem}
          close={() => {
            setOpenForm(false);
            getProjectData(page, take);
          }}
          onClose={() => editItem({})}
          onChange={() => getProjectData(page, take)}
        />
      ) : null}
    </div>
  );
}

export default Projects;
