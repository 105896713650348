import { message } from "antd";
import API from "../config/api";
import S3 from "../config/S3";
const S3FileUpload = require("react-s3");
window.Buffer = window.Buffer || require("buffer").Buffer;

const GET = async (url: any, params: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any, token?: string) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const PUT = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DELETE = async (url: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const FILE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("file = = = >", file);
      if (file && file.name) {
        message.loading({
          content: "Uploading File",
          duration: 2,
        });
        const response = await S3FileUpload.uploadFile(file, S3);
        if (response && response.location) {
          resolve(response.location);
        } else {
          reject("faild");
        }
      } else {
        resolve(null);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export { GET, POST, PUT, DELETE, FILE_UPLOAD };
