import { useEffect, useState } from "react";
import { Button, Input, message, notification } from "antd";
import PageHeader from "../route/pageHeader";
import { BsBuildings } from "react-icons/bs";
import Tables from "./table";
import Forms from "./form";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";

function Companies() {
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const { Search } = Input;

  useEffect(() => {
    getData();
  }, []);


  const getData = async () => {
    try {
      setLoading(true);
      let url = API.GET_COMPANY + `?order=DESC&page=1&take=50`;
      const Company: any = await GET(url, {});
      if (Company?.status) {
        console.log(Company);
        setCompanyData(Company?.data);
      } else {
        setCompanyData([]);
        Notifications["error"]({
          message: "No data found",
        });
      }
    } catch (err) {
      setLoading(false);
      Notifications["error"]({
        message: "Network error !",
        description: "Unable to fetch data",
      });
    }
  };

  const onSearch = async (value: any) => {
    setLoading(true);
    try {
      const url = `${API.FILTER_COMPANY}?companyName=${
        value ?? ""}`;
      const response: any = await GET(url, {});

      if (response.data.length === 0) {
        message.warning("No companies found for the provided criteria.");
      } else {
        setCompanyData(response.data);
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
    } finally {
      setLoading(false);
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  const deleteItem = async (id: any) => {
    const url = API.GET_COMPANY + "/" + id;
    try {
      const response: any = await DELETE(url);
      if (response) {
        message.success("Succesfully Deleted");
        getData();
      } else {
        message.error("Something went wrong");
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  return (
    <div className="Companies">
      {contextHolder}
      <PageHeader title={"Companies"} Icon={<BsBuildings />}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Search
            type="primary"
            size="large"
            enterButton="Search"
            placeholder="Search Companies"
            onSearch={onSearch}
          />
          &nbsp; &nbsp;
          <Button size="large" onClick={() => setOpenForm(true)}>
            Add Company +{" "}
          </Button>
        </div>
      </PageHeader>
      <Tables
        setSelectedItem={setSelectedItem}
        setOpenForm={setOpenForm}
        data={companyData}
        delete={(id: any) => deleteItem(id)}
      />
      {openForm ? (
        <Forms
          visible={openForm}
          data={selectedItem}
          close={() => {
            setOpenForm(false);
            getData();
          }}
          onClose={() => editItem({})}
          onChange={() => getData()}
        />
      ) : null}
    </div>
  );
}

export default Companies;
