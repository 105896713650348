import React, { useEffect, useState } from "react";
import { Button, Checkbox, List, Modal, message } from "antd";
import LoadingBox from "../../../components/loading";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { ExecuteFunction } from "../../../shared/helpers/executeFn";
function removeByIndex(array: any[], indexToRemove: number) {
  const index = array.findIndex((data) => data?.id == indexToRemove);
  if (index !== -1) {
    return array?.slice(0, index)?.concat(array?.slice(index + 1));
  }
  return array;
}
const TemplateModal = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [selected, setSelected] = useState<any[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);

  const fetchTemplates = async (pag: number = 1) => {
    const url = API.TEMPLATES_LIST + `?order=DESC&take=20&page=${pag}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setTemplates(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);
  return (
    <Modal
      title={
        <div className="Projects-txt1 ms-0">{`Message Templates (${
          meta?.total_count ?? 0
        })`}</div>
      }
      open={props?.open}
      onOk={() => props?.onCancel()}
      onCancel={() => props?.onCancel()}
      footer={false}
    >
      <div
        style={{ height: "40vh", overflowY: "scroll", scrollbarWidth: "none" }}
      >
        {loading ? (
          <LoadingBox />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={[{ name: "Name", content: "Content" }, ...templates]}
            renderItem={(item, index) => (
              <List.Item
                className={`contacts-list ${
                  item.name == "Name" ? "underline" : ""
                }`}
              >
                <div
                  className={`col-11 ${item?.name == "Name" ? "fw-bold" : ""}`}
                >
                  {item?.name}
                </div>
                {item?.name == "Name" ? null : (
                  <div className="col-1">
                    <Checkbox
                      onChange={(val) => {
                        let array = [];
                        if (selected?.some((data) => data?.id == item?.id)) {
                          array = removeByIndex(selected, item?.id);
                        } else {
                          if (props?.multi === true) {
                            array = [...selected, { ...item }];
                          } else {
                            array = [{ ...item }];
                          }
                        }
                        setSelected(array);
                      }}
                      checked={
                        selected?.some((data) => data?.id == item?.id)
                          ? true
                          : false
                      }
                      defaultChecked={false}
                    ></Checkbox>
                  </div>
                )}
              </List.Item>
            )}
          />
        )}
      </div>
      <div className="d-flex gap-2 justify-content-end">
        <Button onClick={() => ExecuteFunction(props?.onCancel)}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            if (selected.length) {
              ExecuteFunction(props?.getTemplate, selected);
              ExecuteFunction(props?.onCancel);
            } else {
              message.error("Please Select any Template");
            }
          }}
        >
          {`Select Template`}
        </Button>
      </div>
    </Modal>
  );
};

export default TemplateModal;
