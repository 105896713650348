import React, { useEffect, useState } from "react";
import PageHeader from "../route/pageHeader";
import { Button, message } from "antd";
import Search from "antd/es/input/Search";
import { HiTemplate } from "react-icons/hi";
import LoadingBox from "../../components/loading";
import DataTables from "./dataTables";
import FormModal from "./formModal";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";

function Templates() {
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("add");
  const [selected, setSelected] = useState<any>({});
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState<any[]>([]);

  useEffect(() => {
    fetchTemplates();
  }, [search]);

  const fetchTemplates = async (pag: number = 1) => {
    const url =
      API.TEMPLATES_LIST +
      `?order=DESC&take=${pageSize}&page=${pag}&name=${search}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setTemplates(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
      setSearching(false);
    }
  };

  const deleteTemplate = async (item: any) => {
    const url = API.TEMPLATES_DELETE + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response?.status) {
        message.success("Contact Deleted Successfully.");
        fetchTemplates();
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
    }
  };

  const changePage = async (page: number) => {
    await fetchTemplates(page);
    setPage(page);
  };

  const selectItem = (item: any) => {
    setSelected(item);
    setOpenModal(!openModal);
  };

  return (
    <>
      <PageHeader
        icon={<HiTemplate />}
        title={"My Templates"}
        desc={"Add your Templates here"}
        back={true}
      >
        <Search
          placeholder="Search Name"
          onSearch={(val) => {
            setSearch(val);
            setSearching(true);
          }}
          enterButton
          allowClear
          style={{ width: 200 }}
          loading={searching}
        />
        <Button type="primary" className="ms-2" onClick={() => selectItem({})}>
          Add Template +
        </Button>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTables
          data={templates}
          deleteContact={deleteTemplate}
          selectItem={(item: any) => selectItem(item)}
          page={page}
          pageSize={pageSize}
          meta={meta}
          changePage={changePage}
        />
      )}
      {openModal ? (
        <FormModal
          open={openModal}
          data={selected}
          close={() => selectItem({})}
          onChange={() => changePage(page)}
        />
      ) : null}
    </>
  );
}

export default Templates;
