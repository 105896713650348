import { MdFormatListBulletedAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
function CreateCard() {
  const navigation = useNavigate();
  return (
    <div
      className="User-CreateCard"
      onClick={() => navigation("/auth/newProject")}
    >
      <MdFormatListBulletedAdd size={35} color="#3468eb" />
      <div style={{ margin: 5 }} />
      <div>Create Project</div>
    </div>
  );
}

export default CreateCard;
