import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoDocumentAttachOutline } from "react-icons/io5";

function FileItem(props:any) {

  const checkFileType =() =>{
    console.log(props?.files?.url)
    var parts = props?.files?.url.split('.');
    if (parts.length > 1 && parts[parts.length - 1] !== '') {
      return parts[parts.length - 1];
  } else {
      return null;
  }
  }
  
    return (
      <div className="Projects-FileItem">
      <div>
        <img src={props?.files?.url} className="Projects-FileItemImag" />
      </div>
      <div style={{ flex: 1 }}>
        {props?.files?.file?<>
          <div>{props?.files?.file?.name}</div>
        <div style={{ fontSize: 10 }}>
          {(Number(props?.files?.file?.size) / (1024 * 1024)).toFixed(2)}{" "}
          mb
        </div>
        </>:<>{props?.files?.url}</>}   
      </div>
      <div onClick={() => props?.remove()}>
        <IoMdCloseCircleOutline size={25} color="red" />
      </div>
    </div>
    );
  }
  
  export default FileItem;
  