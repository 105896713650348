import React, { useEffect, useState } from "react";
import PageHeader from "../route/pageHeader";
import { IoIosContact } from "react-icons/io";
import { Button, message } from "antd";
import DataTables from "./dataTables";
import LoadingBox from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";
import FormModal from "./formModal";
import Search from "antd/es/input/Search";
import { Row, Col } from "react-bootstrap";
import { SiMicrosoftexcel } from "react-icons/si";
import ExcelModal from "./excelModal";

function Contacts() {
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [openEcxelModal, setOpenEcxelModal] = useState(false);

  const pageSize = 12;
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState<any[]>([]);

  useEffect(() => {
    fetchContacts();
  }, [search]);

  const fetchContacts = async (pag: number = 1) => {
    const url =
      API.CONTACTS_LIST +
      `?order=DESC&take=${pageSize}&page=${pag}&name=${search}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setContacts(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
      setSearching(false);
    }
  };

  const deleteContact = async (item: any) => {
    const url = API.CONTACTS_DELETE + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response?.status) {
        message.success("Contact Deleted Successfully.");
        fetchContacts();
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
    }
  };

  const changePage = async (page: number) => {
    await fetchContacts(page);
    setPage(page);
  };

  const selectItem = (item: any) => {
    setSelected(item);
    setOpenModal(!openModal);
  };

  return (
    <>
      <PageHeader
        icon={<IoIosContact />}
        title={"My Contacts"}
        desc={"Add your contacts here"}
        back={true}
      >
        <Search
          placeholder="Search Name"
          onSearch={(val) => {
            setSearch(val);
            setSearching(true);
          }}
          enterButton
          allowClear
          style={{ width: 200 }}
          loading={searching}
        />
        <Button type="primary" className="ms-2" onClick={() => selectItem({})}>
          Add Contact +
        </Button>
        <Button
          type="primary"
          className="ms-2"
          onClick={() => setOpenEcxelModal(true)}
          icon={<SiMicrosoftexcel />}
        ></Button>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <Row>
          <Col sm={selected?.id ? 8 : 12} xs={12}>
            <DataTables
              data={contacts}
              selected={selected}
              deleteContact={deleteContact}
              details={(item: any) => setSelected(item)}
              selectItem={(item: any) => selectItem(item)}
              page={page}
              pageSize={pageSize}
              meta={meta}
              changePage={changePage}
            />
          </Col>
          <Col sm={selected?.id ? 4 : 12} xs={12}></Col>
        </Row>
      )}
      {openModal ? (
        <FormModal
          open={openModal}
          data={selected}
          close={() => selectItem({})}
          onChange={() => changePage(page)}
        />
      ) : null}
      {openEcxelModal ? (
        <ExcelModal
          open={openEcxelModal}
          close={() => setOpenEcxelModal(false)}
          onChange={() => changePage(page)}
        />
      ) : null}
    </>
  );
}

export default Contacts;
