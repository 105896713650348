import "./styles.scss";
import { Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

import Header from "./header";

import Dashboard from "../dashboard";

import Projects from "../projects";
import NewProject from "../projects/newForm";
import CoastUsage from "../coastUsage";
import Payments from "../payments";
import Contacts from "../contacts";
import Templates from "../templates";

function UserRoute() {
  return (
  
    <div className="UserRouteBox">
    <Header/>
    <Container>
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path="/project/:id" element={<Projects />} />
        <Route path="/newProject" element={<NewProject />} />
        <Route path="/coastUsage" element={<CoastUsage />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/templates" element={<Templates />} />
        
      </Routes>
    </Container>
    </div>
  );
}

export default UserRoute;
