import { Popconfirm, Table, Tag } from "antd";
import { CiEdit, CiRead } from "react-icons/ci";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";


function Tables(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (item: any) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Expire Date",
      dataIndex: "expire",
      render: (item: any) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <div>
          {item ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: any, item:any) => (
        <div className="table-item">
          <div onClick={()=>navigate(`/admin/projects/${id}`)}>
            <CiRead size={25} color="blue"/>
          </div>
          <div onClick={()=> {props?.setOpenForm(true); props?.setSelectedItem(item)}}>
            <CiEdit size={25} color="orange"/>
          </div>
          <Popconfirm
                      placement="left"
                      title={"are you sure Delete ?"}
                      description={
                        "You will not be able to retreive this data."
                      }
                      onConfirm={() => props.delete(id)}
                      okText="Yes"
                      cancelText="No"
                    >
          <div>
            <MdDelete size={25} color="red"/>
          </div>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return <Table size="small" dataSource={props?.data} columns={columns} />;
}

export default Tables;
