import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import { LiaUserSolid } from "react-icons/lia";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { Button, Popover } from "antd";
import { logout } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
function Header() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const User = useSelector((state: any) => state.User);

  const Logout = () =>{
    dispatch(logout());
    navigation(`/`);
  }

  return (
    <div className="User-header">
      <div>
        <img src={logo} className="User-headerLogo" />
      </div>
      <div style={{ flex: 1 }} />
      <div className="User-headerBox2"><IoDocumentTextOutline/></div>
      <div className="User-headerBox2"><IoIosNotificationsOutline/></div>
      <div className="User-headerBox">
        <div className="User-headerBox3">
          <div className="User-headertxt1">{User?.user?.companyName}</div>
          <div className="User-headertxt2">{User?.user?.email}</div>
        </div>
        <div>
          <Popover placement="bottomLeft" content={<div>
            <LiaUserSolid size={40} color="#3468eb"/>
            <div className="User-headertxt3">{User?.user?.companyName}</div>
            <div className="User-headertxt4">{User?.user?.email}</div>
            <br/>
            <Button danger onClick={()=>Logout()}>Logout</Button>
          </div>}>
          <LiaUserSolid size={40} color="#3468eb"/>
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default Header;
