import PageHeader from "../route/pageHeader";
import { IoListSharp } from "react-icons/io5";
function Logs() {
  return (
    <div className="Logs">
        
        <PageHeader title={"Logs"} Icon={<IoListSharp/>}/>
    </div>
  );
}

export default Logs;
