import { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
  Select,
} from "antd";
import { Col, Row } from "react-bootstrap";
import API from "../../config/api";
import { POST, PUT } from "../../utils/apiCalls";

function ProjectForms(props: any) {
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [name, setName] = useState(props?.data?.name);
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (props?.data?.id) {
      form.setFieldsValue({
        name: props?.data?.name,
        limit: props?.data?.limit,
        type: props?.data?.type,
        bundleId: props?.data?.bundleId,
        usage: props?.data?.usage,
        status: props?.data?.status,
      });
    } else {
      form.resetFields();
    }
  }, [props?.data?.id, form]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        name: values.name,
        companyId: parseInt(props?.id),
        limit: values.limit,
        type: label,
        bundleId: values.bundleId,
        usage: values.usage,
        status: values.status,
      };
      console.log(values);
      const response: any = props.data.id
        ? await PUT(API.GET_PROJECT + "/" + props?.data?.id, obj)
        : await POST(API.GET_PROJECT, obj);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: "Success",
        });
        setLoading(false);
        props.close();
      } else {
        Notifications["error"]({
          message: "Oops !",
          description: "Failed to create project",
        });
      }
    } catch (err) {
      setLoading(false);
      Notifications["error"]({
        message: "Oops !",
        description: "Something gone wrong.",
      });
    }
  };

  const handleSelectChange = (value:any,item:any) => {
    setLabel(item.label)
    if (name) {
      form.setFieldsValue({
        bundleId:bundleId[value]
      })  
    }
  }

  const bundleId:any= {
    ["1"]: `com.webapp.${name?.toLowerCase()}`,
    ["2"]: `com.android.${name?.toLowerCase()}`,
    ["3"]: `com.ios.${name?.toLowerCase()}`,
    ["4"]: `com.server.${name?.toLowerCase()}`
  };
  

  const options = [
    {
      value: "1",
      label: "Web App",
    },
    {
      value: "2",
      label: "Android",
    },
    {
      value: "3",
      label: "IOS",
    },
    {
      value: "4",
      label: "Server",
    },
  ]

  return (
    <Modal
      title={<div>{`${props?.data?.id ? "Edit" : "New"} Project`}</div>}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={900}
    >
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col sm={6}>
            <div className="form-label">Name</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Project Name" }]}
              name={"name"}
              style={{ marginTop: -8 }}
            >
              <Input size="large" placeholder="Name" onChange={(e)=> setName(e.target.value)}/>
            </Form.Item>
            <Row>
              <Col sm={6}>
                <div className="form-label">Limit</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Limit" }]}
                  name={"limit"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Limit" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Status</div>
                <Form.Item
                  rules={[{ required: true }]}
                  name={"status"}
                  valuePropName="checked"
                >
                  <Checkbox>Active</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <div className="form-label">Type</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Type" }]}
              name={"type"}
              style={{ marginTop: -8 }}
            >
              <Select
                onChange={(value, item)=> handleSelectChange(value,item)}
                showSearch
                size="large"
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options}
              />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <div className="form-label">BundleId</div>
                <Form.Item
                  rules={[{ required: true }]}
                  name={"bundleId"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Bundle ID"  readOnly/>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Usage</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Usage" }]}
                  name={"usage"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Button
                  style={{ height: 50 }}
                  block
                  size="large"
                  onClick={() => props.onClose()}
                >
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  {props?.data?.id ? "Update" : "Done"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ProjectForms;
