import React, { useState } from "react";
import "./styles.scss";
import { Button, Modal, Tag, message } from "antd";
import ImagePicker from "../../components/imagePicker";
import * as XLSX from "xlsx";
import { Table } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";

const ExcelModal = (props: any) => {
  const User = useSelector((state: any) => state?.User);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);

  const readData = async (file: any) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file?.file);
      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws: any = wb.Sheets[wsname];
        const range = XLSX.utils.decode_range(ws["!ref"]);
        const fields = [];
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { c: C, r: range.s.r };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cell = ws[cellRef];
          if (cell && cell.v) {
            fields.push(cell.v.toString());
          }
        }
        console.log("Fields:", fields);
        const data = XLSX.utils.sheet_to_json(ws);
        setContacts(data);
      };
    } catch (err) {
      console.log("err = = = >", err);
    }
  };

  const removeItem = (index: any) => {
    try {
      let arr = contacts;
      arr.splice(index, 1);
      setContacts(arr);
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
      }, 10);
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...");
    }
  };

  const uploadContacts = async () => {
    try {
      setLoading(true);
      var obj = {
        companyId: User?.user?.id,
        type: User?.user?.companyType,
        contacts: contacts,
      };
      console.log("obj", obj);
      var response: any = await await POST(API.CONTACT_EXCEL, obj);
      console.log("response = = = >", response);
      if (response?.status) {
        message.success(
          `Contact ${props?.data?.id ? "Added" : "Updated"} Successfully.`
        );
        props?.onChange();
        props?.close();
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong...");
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<div style={{ fontSize: 25 }}>Import Excel</div>}
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => props?.close()}
      width={600}
    >
      <Tag color="blue">Download Sample</Tag>
      <br /> <br />
      {contacts?.length ? (
        <div className="contact-excelModal">
          <Table bordered>
            <thead>
              <tr>
                <td></td>
                <td>Name</td>
                <td>Country</td>
                <td>Phone</td>
                <td>Gender</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {contacts?.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td style={{ width: 70 }}>{item?.country}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.gender}</td>
                    <td style={{ width: 20 }} onClick={() => removeItem(index)}>
                      <IoMdClose size={20} color="red" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <ImagePicker
          desc={""}
          type={["xlsx"]}
          fileURL={null}
          onChange={(value: any) => readData(value)}
        />
      )}
      <br />
      <div className="d-flex gap-2 justify-content-end">
        <Button size="large">Cancel</Button>
        <Button
          type="primary"
          loading={loading}
          size="large"
          onClick={() => uploadContacts()}
        >
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default ExcelModal;
