

function Payments() {
  return (
    <div>
        Payments
    </div>
  );
}

export default Payments;
