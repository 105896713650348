import PageHeader from "../route/pageHeader";
import { AiOutlineAreaChart } from "react-icons/ai";
import { Input } from "antd";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { Container } from "react-bootstrap";
import CustomTabs from "./components/tabs";

function Project() {
  const [data, setData] = useState<any>({});
  const { Search } = Input;
  const { id } = useParams();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    const url = API.GET_PROJECT + "/" + id;
    try {
      const response: any = await GET(url, {});
      if (response.status) {
        setData(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div>
        <PageHeader title={data?.name} Icon={<AiOutlineAreaChart size={30} />}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Search
              type="primary"
              size="large"
              enterButton="Search"
              placeholder="Search Projects"
            />
          </div>
        </PageHeader>
      </div>
      <Container>
      <CustomTabs/>

      <div className="pro-container">

      </div>
      </Container>
    </>
  );
}

export default Project;
