import { Area } from '@ant-design/charts';
import { Card } from 'antd';

const Charts = (props: any) => {
  const data = [
    { year: '1991', value: 3 },
    { year: '1992', value: 4 },
    { year: '1993', value: 3.5 },
    { year: '1994', value: 5 },
    { year: '1995', value: 4.9 },
    { year: '1996', value: 6 },
    { year: '1997', value: 2 },
    { year: '1998', value: 9 },
    { year: '1999', value: 13 },
  ];
  const config = {
    data:data,
    xField: 'year',
    yField: 'value',
    line: {
      style: {
        stroke: '#3468eb',
      },
    },
    smooth: true,
    style: {
      fill: '#d1e2ff',
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
      cursor: 'pointer',
      strokeColor:'#3468eb',
    },
    point: {
      size: 4,
      style: {
        fill: '#3468eb',
        stroke: 'white',
        lineWidth: 2,
      },
    },
    height: 300,
    tooltip:false
  };
  return <Card title={"Analytics"}>
    <Area {...config} /></Card>;
};

export default Charts;
