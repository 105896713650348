import { FaRegCircleUser } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

function ContactItem(props: any) {
  return (
    <div className="Projects-contactItem">
      <div style={{ flex: 1 }}>
        <div style={{fontSize:10}}>{props?.item?.name ? props?.item?.name : "unknown"}</div>
        <div style={{fontSize:14}}>
          +{props?.item?.code} {props?.item?.phone}
        </div>
      </div>
      <div>
        <IoClose color="red" size={20} />
      </div>
    </div>
  );
}

export default ContactItem;
