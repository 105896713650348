import React from "react";
import { Tabs as AntTabs } from "antd";

function CustomTabs() {
    const numberOfTabs = 6; 

    const tabNames = ["General", "Cloud Messaging", "Integration", "Service Accounts", "Data Privacy", "Users and permissions"]; 
  return (
    <div style={{width:"100%",height: "50px"}}>
      <AntTabs
        defaultActiveKey="1"
        // centered
        tabBarGutter={20}
        items={new Array(numberOfTabs).fill(null).map((_, i) => {
          const id = String(i + 1);
          return {
            label: tabNames[i] || `Tab ${id}`, 
            key: id,
          };
        })}
      />
    </div>
  );
}

export default CustomTabs;
