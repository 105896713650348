import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/protectedRoute";
import { ConfigProvider } from "antd";

import LoginScreen from "./website/loginScreen";
import ContactScreen from "./website/contactScreen";
import UserRoute from "./user/route";
import AdminRoute from "./admin/route";

function App() {
  const User = useSelector((state: any) => state.User);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3468eb",
          fontFamily: "DMSans-Medium",
          borderRadius: 8,
        },
      }}
    >
      <Routes>
        <Route index element={<LoginScreen />} />;
        <Route index element={<ContactScreen />} />;
        <Route
          path="/auth/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
              <UserRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
              <AdminRoute />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
