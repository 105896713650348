import { Pagination, Popconfirm, Card } from "antd";
import { MdDeleteOutline } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
function DataTables({
  data,
  deleteContact,
  selectItem,
  page,
  pageSize,
  meta,
  changePage,
}: any) {
  return (
    <>
      <br />
      <Row>
        {data?.map((item: any) => {
          return (
            <Col sm={3} key={item?.id}>
              <Card
                hoverable
                style={{ width: "100%", height: "100%" }}
                cover={
                  <img
                    style={{ height: 250, objectFit: "cover" }}
                    alt="example"
                    src={item?.image}
                  />
                }
                actions={[
                  <AiOutlineEdit size={25} onClick={()=>selectItem(item)}/>,
                  <Popconfirm
                    placement="bottomRight"
                    title={"Are you sure to delete?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteContact(item)}
                  >
                    <MdDeleteOutline color="red" size={25} />
                  </Popconfirm>,
                ]}
              >
                <Card.Meta title={item?.name} />
              </Card>
            </Col>
          );
        })}
      </Row>
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.total_count || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: number, size: number) => changePage(current)}
        />
      </div>
    </>
  );
}

export default DataTables;
