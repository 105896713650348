import { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
  DatePicker,
} from "antd";
import { Col, Row } from "react-bootstrap";
import API from "../../config/api";
import { POST, PUT } from "../../utils/apiCalls";
import dayjs from "dayjs";

function Forms(props: any) {
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  useEffect(() => {
    if (props?.data?.id) {
      const expire = dayjs(props.data.expire);
      form.setFieldsValue({
        companyName: props?.data?.companyName,
        plan: props?.data?.plan,
        status: props?.data?.status,
        limit: props?.data?.limit,
        payment: props?.data?.payment,
        amount: props?.data?.amount,
        rate: props?.data?.rate,
        expire: expire,
        userName:props?.data?.userName,
        password: props?.data?.password
      });
    } else {
      form.resetFields();
    }
  }, [props?.data?.id, form]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        companyName: values.companyName,
        plan: values.plan,
        status: values.status,
        limit: values.limit,
        payment: values.payment,
        amount: values.amount,
        rate: values.rate,
        expire: values.expire,
        usage: "",
        userName:values.userName,
        password: values.password,
        userType:'user'
      };
      const response: any = props.data.id
        ? await PUT(API.GET_COMPANY + '/' + props?.data?.id, obj)
        : await POST(API.GET_COMPANY, obj);
      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: "Company created",
        });
        setLoading(false);
        props.close();
      } else {
        Notifications["error"]({
          message: "Oops !",
          description: "Failed to create company",
        });
      }
    } catch (err) {
      setLoading(false);
      Notifications["error"]({
        message: "Oops !",
        description: "Something gone wrong.",
      });
    }
  };

  return (
    <Modal
      title={<div>{`${props?.data?.id ? "Edit" : "New"} Company`}</div>}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={900}
    >
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col sm={6}>
            <div className="form-label">User Name</div>
            <Form.Item
              rules={[{ required: true, message: "Enter User Name" }]}
              name={"userName"}
              style={{ marginTop: -8 }}
            >
              <Input size="large" placeholder="User Name" />
            </Form.Item>
            <div className="form-label">Company Name</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Company Name" }]}
              name={"companyName"}
              style={{ marginTop: -8 }}
            >
              <Input size="large" placeholder="Company Name" />
            </Form.Item>

            <Row>
              <Col sm={6}>
                <div className="form-label">Plan</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter The Plan" }]}
                  name={"plan"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Plan" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Rate/ Otp</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter The Rate" }]}
                  name={"rate"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Rate" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              rules={[{ required: true, message: "Choose" }]}
              name={"status"}
              valuePropName="checked"
            >
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>

          <Col sm={6}>
            <div className="form-label">Password</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Password" }]}
              name={"password"}
              style={{ marginTop: -8 }}
            >
              <Input size="large" placeholder="Password" />
            </Form.Item>
            <div className="form-label">OTP Limit</div>
            <Form.Item
              rules={[{ required: true, message: "Enter OTP Limit" }]}
              name={"limit"}
              style={{ marginTop: -8 }}
            >
              <Input size="large" placeholder="OTP Limit" />
            </Form.Item>
            
            <Row>
              <Col sm={6}>
                <div className="form-label">Payment</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Payment" }]}
                  name={"payment"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Payment" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Amount</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Amount" }]}
                  name={"amount"}
                  style={{ marginTop: -8 }}
                >
                  <Input size="large" placeholder="Amount" type="number" />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-label">Expirey Date</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Expirey Date" }]}
              name={"expire"}
              style={{ marginTop: -8 }}
            >
              <DatePicker
                size="large"
                placeholder="Choose Expiry Date"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <Button
                  style={{ height: 50 }}
                  block
                  size="large"
                  onClick={() => props.onClose()}
                >
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  {props?.data?.id ? "Update" : "Done"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Forms;
