import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, AutoComplete, message } from "antd";
import { Col, Row } from "react-bootstrap";
import { IoMdSend } from "react-icons/io";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { MdOutlineContactMail } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdOutlinePreview } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineContacts } from "react-icons/md";

import ContactModal from "../components/contactModal";
import FilePicker from "../components/filePicker";
import ExcelModal from "../components/excelModal";
import TemplateModal from "../components/templateModal";
import PreviewModal from "../components/previewModal";
import FileItem from "../components/fileItem";
import ContactItem from "../components/contactItem";

import Contacts from "../components/contact.json";
import { FILE_UPLOAD, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";

function Broadcast(props: any) {
  const User = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const [excelModal, setExcelModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [number, setNumber] = useState<any>(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const [contactsOptions, setContactsOptions] = useState<any>([]);

  const [filesModal, setFilesModal] = useState(false);
  const [files, setFiles] = useState<any>(null);

  const [form] = Form.useForm();
  const [templateModal, setTemplateModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const searchContact = (numb: any) => {
    try {
      setNumber(numb);
      var arr = Contacts;
      var filterData: any = arr.filter((item) => {
        return item?.Number === numb
          ? { label: item?.Name, value: item?.Number }
          : null;
      });
      if (filterData?.length) {
        filterData = filterData.map((item: any) => ({
          label: item?.Name,
          value: item?.Number,
        }));
        setContactsOptions(filterData);
      }
    } catch (err) {}
  };

  const addContact = (numb: any) => {
    try {
      if (numb?.length >= 10) {
        const checkExist = contacts?.findIndex((i: any) => i.phone === numb);
        if (checkExist >= 0) {
          message.error("Number already exist");
        } else {
          let obj = {
            Name: null,
            phone: numb,
            gender: null,
            type: User?.user?.companyType,
          };
          setContacts((prev) => [obj, ...prev]);
          setTimeout(() => {}, 10);
        }
      } else {
        message.error("sorry Number not valid");
      }
    } catch (err) {
      message.error("sorry Number not valid");
    }
  };

  const getTemplate = (templat: any) => {
    message.success("Template Selected Successfully.");
    form.setFieldsValue({
      message: templat?.[0]?.content,
    });
  };

  const getContacts = (contact: any[]) => {
    const duplicates = contacts?.filter((itemA) =>
      contact?.some((itemB) => itemB.phone == itemA.phone)
    );
    if (duplicates?.length > 0) {
      message.error("Phone Number has been already added");
    } else {
      setContactModal(false);
      setContacts((prev) => [...contact, ...prev]);
      message.success(`${contact?.length} Phone Numbers has been Selected`);
    }
  };

  const handleSubmit = async (val: any) => {
    try {
      const phoneNumbers = [];
      for (let i = 0; i < contacts.length; i++) {
        phoneNumbers.push(contacts[i].phone);
      }
      var file: any = files?.url;
      if (files?.file) {
        file = await FILE_UPLOAD(files?.file);
      }
      let reqObj = {
        attachment: file,
        message: val.message,
        to: phoneNumbers,
        batchSize: val.batchCount,
      };
      let broadcastRes = await POST(
        API.BROADCAST_V1,
        reqObj,
        props?.data?.token
      );

      console.log("broadcastRes", broadcastRes);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div style={{ paddingBottom: 20 }}>
      <div className="Projects-txt1">Broadcast Message</div>
      <div className="Projects-Broadcast">
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col sm={6} xs={12}>
              <div
                className="Projects-rowBox"
                style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 5 }}
              >
                <div className="Projects-Broadcasttxt1">Whatsapp Numbers</div>
                <div style={{ flex: 1 }} />
                <div
                  style={{ marginBottom: 10 }}
                  onClick={() => setExcelModal(true)}
                >
                  <SiMicrosoftexcel size={25} color={"#3468eb"} />
                </div>
              </div>
              <div className="Projects-contactList">
                <Row>
                  <Col sm={2} xs={4}>
                    <Button
                      size="large"
                      block
                      style={{ height: 45 }}
                      icon={
                        <MdOutlineContactMail size={25} color={"#3468eb"} />
                      }
                      onClick={() => setContactModal(true)}
                    ></Button>
                  </Col>
                  <Col sm={10} xs={8}>
                    <Form.Item noStyle>
                      <AutoComplete
                        style={{ width: "100%" }}
                        size="large"
                        options={contactsOptions}
                        onChange={searchContact}
                        onSelect={addContact}
                      >
                        <Input
                          size="large"
                          type="number"
                          placeholder="91 1234567890"
                          suffix={
                            <IoMdAdd
                              size={30}
                              color="green"
                              onClick={() => addContact(number)}
                            />
                          }
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ marginLeft: -15, marginRight: -15 }}>
                  <hr />
                  {contacts?.length ? (
                    <div>
                      {contacts.map((item: any) => {
                        return <ContactItem item={item} />;
                      })}
                    </div>
                  ) : (
                    <div className="Projects-emptyBox">
                      <MdOutlineContacts size={30} />
                      <div>No Numbers</div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div className="Projects-rowBox">
                <div className="Projects-Broadcasttxt1">Message</div>
                <div style={{ flex: 1 }} />
                <div style={{ marginBottom: 15 }}>
                  <Button size="small" onClick={() => setTemplateModal(true)}>
                    Templates
                  </Button>
                </div>
              </div>
              <Form.Item
                name={"message"}
                rules={[{ required: true, message: "required" }]}
              >
                <Input.TextArea
                  size="large"
                  rows={6}
                  placeholder="Enter Message"
                />
              </Form.Item>
              <div className="Projects-Broadcasttxt1">Aattachments</div>
              {files?.file ? (
                <Form.Item>
                  <FileItem files={files} remove={() => setFiles(null)} />
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    block
                    size="large"
                    icon={<IoDocumentAttachOutline />}
                    style={{ height: 50 }}
                    onClick={() => setFilesModal(true)}
                  >
                    Attachments
                  </Button>
                </Form.Item>
              )}

              <div className="Projects-Broadcasttxt1">Message Settings</div>
              <div className="Projects-FileItem">
                <div className="Projects-rowBox">
                  <div style={{ flex: 1 }}>
                    Wait 10 to 20 seconds after every
                  </div>
                  <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <Form.Item name={"batchCount"}>
                      <Input size="large" style={{ width: 70 }} />
                    </Form.Item>
                  </div>
                  <div>Messages</div>
                </div>
              </div>
              <br />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6} xs={12}></Col>
            <Col sm={6} xs={12}>
              <Row>
                <Col sm={6} xs={12}>
                  <Button
                    block
                    style={{ height: 50 }}
                    icon={<MdOutlinePreview />}
                    onClick={() => setPreviewModal(true)}
                  >
                    Preview
                  </Button>
                </Col>
                <Col sm={6} xs={12}>
                  <Button
                    block
                    type="primary"
                    style={{ height: 50 }}
                    icon={<IoMdSend />}
                    htmlType="submit"
                    loading={loading}
                  >
                    Send Message
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {contactModal ? (
        <ContactModal
          open={contactModal}
          onCancel={() => setContactModal(false)}
          multi={true}
          getContacts={getContacts}
        />
      ) : null}
      {filesModal ? (
        <FilePicker
          open={filesModal}
          fileURL={files?.url}
          onChange={(val: any) => setFiles(val)}
          onCancel={() => setFilesModal(false)}
        />
      ) : null}
      {excelModal ? (
        <ExcelModal open={excelModal} onCancel={() => setExcelModal(false)} />
      ) : null}
      {templateModal ? (
        <TemplateModal
          open={templateModal}
          onCancel={() => setTemplateModal(false)}
          getTemplate={getTemplate}
        />
      ) : null}
      {previewModal ? (
        <PreviewModal
          open={previewModal}
          file={files}
          data={form.getFieldsValue()}
          onCancel={() => setPreviewModal(false)}
        />
      ) : null}
    </div>
  );
}

export default Broadcast;
